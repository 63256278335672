<template>
  <v-container fluid>
    <!-- Boite de dialogue : reponse du serveur -->
    <v-dialog v-model="reponseDialog" persistent width="500">
      <v-card>
        <v-card-title>
          Server answer
          <v-spacer />
          <v-btn color="primary" icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ reponse }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="center" class="text-center mt-6">
      <h1 class="font-weight-light">Please, check your information</h1>
    </v-row>

    <v-row
      align="center"
      justify="center"
      :style="style_centerblock"
      class="text-center"
    >
      <v-form
        ref="checkform"
        v-model="valid"
        lazy-validation
        :style="style_form"
      >
        <!-- Login -->
        <v-text-field
          v-model="this.$store.state.account.login"
          :dark="this.$store.state.darkTheme ? true : false"
          label="Login"
          disabled
        />

        <!-- Firstname -->
        <v-text-field
          v-model="this.$store.state.account.firstname"
          :dark="this.$store.state.darkTheme ? true : false"
          label="Firstname"
          disabled
        />

        <!-- Lastname -->
        <v-text-field
          v-model="this.$store.state.account.name"
          :dark="this.$store.state.darkTheme ? true : false"
          label="Lastname"
          disabled
        />

        <!-- Laboratory -->
        <v-text-field
          v-model="this.$store.state.listLab[0]"
          :dark="this.$store.state.darkTheme ? true : false"
          label="Laboratory"
          disabled
        />

        <!-- Contract type -->
        <v-text-field
          v-model="this.$store.state.account.contracttype"
          :dark="this.$store.state.darkTheme ? true : false"
          label="Contract type"
          disabled
        />

        <!-- Permanent -->
        <v-card-subtitle
          v-if="this.$store.state.account.permanent"
          class="text-left text-muted"
        >
          <v-icon :dark="this.$store.state.darkTheme ? true : false">
            mdi-check-box-outline
          </v-icon>
          You are permanent
        </v-card-subtitle>

        <!-- Contract date -->
        <v-text-field
          v-if="!this.$store.state.account.permanent"
          v-model="this.$store.state.account.infos.contract_date.value"
          :dark="this.$store.state.darkTheme ? true : false"
          label="Contract date"
          disabled
        />

        <!-- E-mail -->
        <v-text-field
          v-model="this.$store.state.account.mail"
          :dark="this.$store.state.darkTheme ? true : false"
          label="E-mail (institutional)"
          disabled
        />

        <!-- Acceptation de la CGU -->
        <v-checkbox
          v-model="cguBoolean"
          :dark="this.$store.state.darkTheme ? true : false"
          class="my-6"
          :rules="cguRules"
          required
        >
          <template v-slot:label>
            <div @click.stop="">
              I have read and accept the
              <a href="javascript:;" @click.stop="cgu = true">
                General Conditions of Use
              </a>
              and
              <a href="javascript:;" @click.stop="legacy = true">
                Legal notices
              </a>
              .
            </div>
          </template>
        </v-checkbox>

        <!-- Bouton actions -->
        <v-row class="my-4">
          <v-btn
            :dark="this.$store.state.darkTheme ? true : false"
            x-large
            color="error"
            href="mailto:sos-gricad@univ-grenoble-alpes.fr"
            class="my-2"
          >
            Cont<v-icon>mdi-at</v-icon>ct to correct information &thinsp;
            <v-icon>mdi-mail-send</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            :dark="this.$store.state.darkTheme ? true : false"
            x-large
            color="success"
            :disabled="!cguBoolean"
            class="my-2"
            @click="validate"
          >
            Valide &thinsp;
            <v-icon>mdi-text-box-check</v-icon>
          </v-btn>
        </v-row>
      </v-form>

      <!-- CGU definie dans une boite de dialogue -->
      <v-dialog v-model="cgu" width="70%" persistent>
        <v-card>
          <v-card-title class="title">
            Conditions Generales d'Utilisation du portail Perseus-ng
          </v-card-title>
          <v-card-text>
            <cgu-content />
            <!-- {{ cguContent }} -->
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text x-large color="primary" @click="cgu = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="legacy" width="70%" persistent>
        <v-card>
          <v-card-title class="title"> Mentions légales </v-card-title>
          <v-card-text>
            <legacy-content />
            <!-- {{ legacyContent }} -->
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text x-large color="primary" @click="legacy = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-btn
      text
      color="white"
      x-large
      exact
      absolute
      bottom
      left
      class="my-2 mx-2"
      height="70px"
      href="https://gricad.gricad-pages.univ-grenoble-alpes.fr/web/publicweb/index.html"
      target="_blank"
    >
      <!-- :style="this.$store.state.darkTheme?'filter: invert(1)':'filter: invert(0)'" -->
      <v-img
        v-if="this.$store.state.darkTheme"
        src="@/assets/logo_gricad_horizontal_dark.png"
        max-width="230px"
      />
      <v-img
        v-else
        src="@/assets/logo_gricad_horizontal.png"
        max-width="230px"
      />
    </v-btn>
    <v-row style="height: 200px" />
  </v-container>
</template>

<script>
import config from "@/config";
import axios from "axios";
import CguContent from "@/components/CguContent";
import LegacyContent from "@/components/LegacyContent";
import store from "../../store";
import checkToken from "../../store/checkToken";
export default {
  components: {
    CguContent,
    LegacyContent,
  },
  data: () => ({
    // Couleur
    red: config.colors.gricad_red_dark1,
    card_bg_dark: config.colors.card_bg_dark,
    card_bg_light: config.colors.card_bg_light,
    barchart_color: config.colors.gricad_blue_dark3,
    blue: config.colors.gricad_oceanblue,
    reponseDialog: false,
    reponse: "",

    valid: true,
    completedform: null,

    // La CGU
    legacy: false,
    legacyContent: "",
    cgu: false,
    cguContent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.",
    cguBoolean: false,
    cguRules: [(v) => !!v || "You must agree to continue!"],
  }),

  computed: {
    style_centerblock() {
      return {
        "background-color": "transparent",
      };
    },
    style_form() {
      return {
        width: `${this.$store.state.windowW / 2}px`,
        "background-color": "transparent",
      };
    },
    style_bigbtn() {
      return {
        height: `${this.$store.state.windowH / 4}px`,
        width: `${this.$store.state.windowW / 4}px`,
        "background-color": this.blue,
      };
    },
  },

  methods: {
    // Action declenchee par le bouton "Valider"
    validate() {
      // TODO : update checkdate + delete member from m-access-locked group
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        var timestamp = Math.floor(Date.now() / 1000);
        axios
          .get(
            "/username/" +
              this.$store.state.account.login +
              "/set-charterDate/" +
              String(timestamp)
          )
          .then((response) => {
            this.reponse += response.data + " \n";
            axios
              .get(
                "/group/m-access-locked/delete-member/" +
                  this.$store.state.account.login
              )
              .then((response) => {
                this.reponse += response.data;
                this.reponseDialog = true;
                if (response.data.includes("SUCCESS")) {
                  const index = this.$store.state.listMachine.indexOf(
                    "m-access-locked"
                  );
                  if (index > -1) {
                    this.$store.state.listMachine.splice(index, 1);
                    console.log(this.$store.state.listMachine);
                  }
                }
              });
          });
      }
    },
    dismiss() {
      this.reponse = "";
      this.reponseDialog = false;
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style lang="scss">
.text-muted {
  opacity: 0.7;
}
</style>
